import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TTourType } from '@frontend-modules/og-onboarding';
import { useRole } from 'hooks';
import { useAppSelector } from 'store/store';

import { TUseOnboarding, useOnboarding } from './useOnboarding';

const OnboardingContext = createContext({
    hasViewedSteps: false,
    newStepsCount: 0,
    hasActiveStep: false,
    isFetching: false,
    startAutoTour: (key: string, isMobile: boolean) => {},
    getPageSteps: (isMobile: boolean) => {},
    startPageTour: (type?: TTourType) => {},
    stopActiveTour: () => {},
});

export const OnboardingProvider: React.FC<{ isReAuth: boolean; isMobile: boolean }> = (props) => {
    const { isReAuth, isMobile, children } = props;

    const onboarding = useOnboarding();
    const history = useHistory();
    const { startAutoTour, stopActiveTour, hasActiveStep } = onboarding;

    const { isStudent } = useRole();
    const { isMobileMenuOpened } = useAppSelector((store) => ({
        isMobileMenuOpened: store.newNavigation.isMobileMenuOpened,
    }));
    const canStartAutoOnboarding = isStudent && !hasActiveStep;
    const path = history?.location?.pathname ?? '/';

    useEffect(() => {
        if (isReAuth) {
            stopActiveTour();
        }
    }, [isReAuth]);

    useEffect(() => {
        if (canStartAutoOnboarding) {
            //Запускаем автотур при первом рендере, если нет других отображаемых шагов
            startAutoTour('app', isMobile);
        }
    }, [isStudent, isMobile]);

    useEffect(() => {
        if (isMobileMenuOpened && canStartAutoOnboarding) {
            startAutoTour('app', isMobileMenuOpened);
        }
    }, [isMobileMenuOpened]);

    useEffect(() => {
        // таймаут нужен, так возможно уже был запрошен онбординг для предыдущей страницы,
        // нужно подождать, пока он посчитается, потом останавливать
        setTimeout(() => {
            stopActiveTour();
        }, 500);
    }, [path]);

    return <OnboardingContext.Provider value={onboarding}>{children}</OnboardingContext.Provider>;
};

export const useOnboardingContext = (): TUseOnboarding => {
    const context = useContext(OnboardingContext);

    if (!context) {
        throw new Error('useOnboardingContext must be used within an OnboardingProvider');
    }

    return context;
};
