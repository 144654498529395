import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ChangeAdaptationParams } from 'api/services/adaptation';
import { changeAdaptationAction } from 'store/actions/adaptation';
import { getAdaptationProgressAction } from 'store/actions/adaptationProgress';
import { FetchStatus } from 'types/api';
import { AdaptationStudent } from 'types/students';

interface ISetAdaptationItemProgressPayload extends ChangeAdaptationParams {
    studentId?: number;
}
export interface AdaptationProgressState {
    getAdaptationProgressStatus: FetchStatus;
    adaptationProgressStudents: AdaptationStudent[];
    error: unknown;
}

export const initialState: AdaptationProgressState = {
    getAdaptationProgressStatus: FetchStatus.INITIAL,
    adaptationProgressStudents: [],
    error: null,
};

const adaptationProgressSlice = createSlice<AdaptationProgressState, SliceCaseReducers<AdaptationProgressState>>({
    name: 'adaptationProgress',
    initialState,
    reducers: {
        setAdaptationItemProgress: (state, { payload }: { payload: ISetAdaptationItemProgressPayload }) => {
            const { id, done, studentId } = payload;

            state.adaptationProgressStudents = state.adaptationProgressStudents.map((progress) => {
                const { student, adaptationSkills } = progress;

                if (student?.id === studentId) {
                    return {
                        ...progress,
                        adaptationSkills: adaptationSkills?.map((skill) => {
                            if (skill.id === id) {
                                return {
                                    ...skill,
                                    done,
                                };
                            }
                            return skill;
                        }),
                    };
                }
                return progress;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdaptationProgressAction.pending, (state) => {
                state.getAdaptationProgressStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAdaptationProgressAction.fulfilled, (state, { payload }) => {
                state.getAdaptationProgressStatus = FetchStatus.FETCHED;
                state.adaptationProgressStudents = payload;
                state.error = null;
            })
            .addCase(getAdaptationProgressAction.rejected, (state, { error }) => {
                state.getAdaptationProgressStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(changeAdaptationAction.fulfilled, (state, action) => {
                state.adaptationProgressStudents = state.adaptationProgressStudents.map((adaptationProgress) => {
                    if (adaptationProgress.student.id === action.meta.arg.studentId) {
                        return {
                            ...adaptationProgress,
                            adaptationSkills: adaptationProgress.adaptationSkills?.map((skill) => {
                                const adaptation = action.payload.find((item) => skill.id === item.id);
                                if (adaptation) {
                                    return {
                                        ...adaptation,
                                        title: adaptation.adaptation,
                                    };
                                }

                                return skill;
                            }),
                        };
                    }

                    return adaptationProgress;
                });
            });
    },
});
export const { setAdaptationItemProgress } = adaptationProgressSlice.actions;

export const adaptationProgressReducer = adaptationProgressSlice.reducer;
