/**
 *
 * @param ctx
 */
export const SCHEDULE_ON_BOARDING = [
    {
        target: '[data-onboarding="privateLayout"]',
        placement: 'center',
        key: 'schedule_1',
    },
    {
        target: '[data-onboarding="schedulePeriodsTab"]', // день/месяц/год
        key: 'schedule_2',
    },
    {
        target: '[data-onboarding="scheduleDateSwitcher"]', // переключатель даты
        key: 'schedule_3',
    },
    {
        target: '[data-onboarding="selectedDaySummary"]', // информация с количеством уроков и заданий
        key: 'schedule_4',
    },
    {
        target: '[data-onboarding="lessonCard"] .lesson-date-card', // первый урок в расписании
        key: 'schedule_5',
    },
    {
        target: '[data-onboarding="timeToLessonStarts"]', // время до начала урока
        key: 'schedule_6',
    },
    {
        target: '[data-onboarding="enterLessonButton"]', // кнопка входа на урок
        key: 'schedule_7',
    },
    {
        target: '[data-onboarding="lessonHomework"]', // карточка с домашкой
        key: 'schedule_8',
    },
    {
        target: '[data-onboarding="homeworksToPass"]', // блок с заданными домашками
        key: 'schedule_9',
    },
    {
        target: '[data-onboarding="weekScheduleHeader"]', // дни недели в расписании на неделю
        key: 'schedule_10',
    },
    {
        target: '[data-onboarding="weekScheduleLessonTime"]', // урок и его время в расписании на неделю
        key: 'schedule_11',
    },
    {
        target: '[data-onboarding="monthScheduleTable"]', // таблица с месячным расписанием
        key: 'schedule_12',
    },
];
