import { ErrorsCaptureWrapperApi } from '@frontend-modules/error-handler';
import { UntraceableApiList } from '@fsd-shared/constants';
import { sleep } from '@lms-elements/utils';
import { ssoRefreshToken } from 'api/services/token';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { setAccessToken } from 'utils/token';

/**
 * // Функция вызывается для того что бы сохранить упавшие запросы
 * // Что бы в случае успешного перелогина в компоненте вызвать callback с новыми токенами
 * // если токен есть то перезапрашиваем
 * // если нет возвращаем ошибку
 * @param api
 * @param resolve
 * @param reject
 * @param callback
 * @param originalRequest
 */
const resolveRequest = (
    api: AxiosInstance,
    resolve: Function,
    reject: Function,
    callback: Function,
    originalRequest: AxiosRequestConfig,
) => {
    callback?.((accessToken: string) => {
        if (accessToken) {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(api(originalRequest));
        } else {
            reject();
        }
    });
};

let isTokenUpdating = false; // флаг отвечающий за состояние обновления токена
let isAwaitReAuth = false; // флаг отвечающий за состояния перелогина

export const ApiResponder = (
    api: AxiosInstance,
    callback: Function,
    logout?: Function,
    showErrorReportBtn?: boolean,
): void => {
    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error: AxiosError<never>) => {
            const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };
            const responseStatus = error?.response?.status;
            if (responseStatus === 403) {
                logout?.();
                return Promise.reject(error);
            }
            if (responseStatus === 401) {
                // если токен обновляется, то зацикливаем апихи, которые вызвались после начала обновления токена
                // что бы запросы не сыпалось на бэк, пока мы не обновили access
                if (isTokenUpdating) {
                    await sleep(1000);

                    // если isAwaitReAuth == true, то отправляем зацикленные запросы в callback,
                    // что бы сохранить их и перезапросить после перелогина
                    // сделано так, потому что в случае перелогина мы сами управляем вызовом "упавших" запросов
                    // если isAwaitReAuth == false, зацикливаем запрос ожидая обновления токена
                    if (isAwaitReAuth) {
                        return new Promise((resolve, reject) =>
                            resolveRequest(api, resolve, reject, callback, originalRequest),
                        );
                    } else {
                        return api(originalRequest);
                    }
                }
                isTokenUpdating = true;
                // пытаемся обновить токен
                try {
                    const { access } = await ssoRefreshToken();

                    access && setAccessToken(access);

                    // при удачном обновлении токена, отключаем флаг обновления
                    // и вызываем упавший запрос, который инициировал обновление токена
                    isTokenUpdating = false;
                    return api(originalRequest);
                } catch (e) {
                    // если обновление токена упало,
                    // то ставим isAwaitReAuth что бы апихи,
                    // которые вызвались после начала обновления токена,
                    // попали в callback и сохранились
                    isAwaitReAuth = true;

                    // при завершении процесса перелогина(успешного или нет)
                    // сбрасываем флаги
                    return new Promise((resolve, reject) =>
                        resolveRequest(api, resolve, reject, callback, originalRequest),
                    ).finally(() => {
                        isTokenUpdating = false;
                        isAwaitReAuth = false;
                    });
                }
            } else {
                if (error.response) {
                    ErrorsCaptureWrapperApi({
                        error,
                        UntraceableApiList: UntraceableApiList,
                        showAlertReportBtn: showErrorReportBtn,
                    });
                } else {
                    ErrorsCaptureWrapperApi({
                        error: {
                            message: 'Ошибка соединения с сервером',
                            response: { ...error.config },
                        },
                        showAlertReportBtn: false,
                    });
                }
                return Promise.reject(error).catch((error: AxiosError) => {
                    throw error;
                });
            }
        },
    );
};
