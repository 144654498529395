import { commonHost, host } from 'constants/apiMethods';
/**
 * @description Список неотслеживаемых апи для Sentry
 * Можно уточнить, какие конкретно статусы не обрабатывать в sentry, передав список в statuses
 * Можно передать alert=true, чтобы на клиенте отображалось уведомление об ошибке
 *
 * ПРИМЕР: {url: 'some_api', statuses: [400], alert: true,} - 400 ошибка от some_api не будет передана в sentry,
 * но ошибка отобразится на клиенте
 */

export const UntraceableApiList = [
    {
        url: `${host}/alert/`,
    },
    {
        url: `${commonHost}/onboard/v1/`,
    },
    {
        url: `/actions/progress/create/`,
    },
];
