/**
 *
 * @param ctx
 */
export const APP_ON_BOARDING = [
    {
        target: '[data-onboarding="privateLayout"]',
        title: 'Мы рады приветствовать вас в \n Онлайн Гимназии №1!',
        placement: 'center',
        key: 'app_1',
        isStartStep: true,
    },
    {
        target: '[data-onboarding="onboardingButton"]', //Кнопка онбординга
        key: 'app_2',
    },
    {
        target: '[data-onboarding="burgerMenu"]:not(.header-burger-menu_opened)',
        key: 'app_3',
        showNextAfterClick: true,
    },
    {
        target: '.chat-widget-icon', // Кнопка чата
        key: 'app_4',
        placement: 'top',
    },
    {
        target: '[data-onboarding="pagesList"]', //навигация
        key: 'app_5',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="/"]', // обзор
        key: 'app_6',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="/news"]', //новости
        key: 'app_7',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="/new-subjects"]', //предметы
        key: 'app_8',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="/schedule"]', //Раcписание
        key: 'app_9',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="/homework"]', //Домашки
        key: 'app_10',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="/performance"]', //Успеваемость
        key: 'app_11',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="/library"]', //Библиотека
        key: 'app_12',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
    {
        target: '[data-onboarding="notificationButton"]', //Уведомления
        key: 'app_13',
        parentForVisibilityChecking: '.header-mobile-menu',
    },
];
