/**
 *
 * @param ctx
 */
export const HOME_ON_BOARDING = [
    {
        target: '[data-onboarding="privateLayout"]',
        placement: 'center',
        key: 'home_1',
    },
    {
        target: '[data-onboarding="scheduleBlock"]', //Блок с расписанием (слева)
        key: 'home_2',
    },
    {
        target: '[data-onboarding="lessonsCount"]', //Счетчик прошедших уроков
        key: 'home_3',
    },
    {
        //class="schedule-item-statusLabel schedule-item-statusLabel_status_future"
        target: '.schedule-item-statusLabel_status_future', //'[data-onboarding="lessonsStartTime"]', //Время начала урока
        key: 'home_4',
    },
    {
        target: '.schedule-item-list-link button.button.button_view_action', //'[data-onboarding="lessonEntryButton"]', // Кнопка входа на урок
        key: 'home_5',
    },
    {
        target: '.schedule-item-list-link:nth-child(1) .schedule-item.schedule-item_status_future button', // Время до начала урока
        key: 'home_6',
    },
    {
        target: '[data-onboarding="scheduleCalendar"]', // Календарь в расписании
        key: 'home_7',
    },
    {
        target: '[data-onboarding="homeworkBlock"]', //Блок с домашками (справа)
        key: 'home_8',
    },
    {
        target: '[data-onboarding="homeworkFilter"]', //Фильтр (скрыть рекомендуемые)
        key: 'home_9',
    },
    {
        target: '[data-onboarding="timeBeforeTasksPass"]', //Время до сдачи домашки
        key: 'home_10',
    },
    {
        target: '[data-onboarding="overdueTask"]', // Карточка просроченного дз
        key: 'home_11',
    },
    {
        target: '[data-onboarding="archive"]', //архив
        key: 'home_12',
    },
];
