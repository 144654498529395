/**
 *
 * @param ctx
 */
export const NEWS_ON_BOARDING = [
    {
        target: '[data-onboarding="privateLayout"]',
        placement: 'center',
        key: 'news_1',
    },
    {
        target: '[data-onboarding="newsCard"]', //карточка с новостью
        key: 'news_2',
    },
    {
        target: '.news-page .dateSlider', //'[data-onboarding="newsDateFilter"]', //Выбор даты
        key: 'news_3',
    },
];
