import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import $Onboarding, { TTourType } from '@frontend-modules/og-onboarding';
import { ONBOARDING_STEPS } from '@fsd-features/onboarding/config';
import { getOnboardingDelay, getOnboardingKey } from '@fsd-features/onboarding/utils';

export type TUseOnboarding = {
    hasViewedSteps: boolean;
    newStepsCount: number;
    hasActiveStep: boolean;
    isFetching: boolean;
    startAutoTour: (key: string, isMobile: boolean) => void;
    getPageSteps: (isMobile: boolean) => void;
    startPageTour: (type?: TTourType) => void;
    stopActiveTour: () => void;
};
export const useOnboarding = (): TUseOnboarding => {
    const history = useHistory();
    const EXOnboarding = useRef<$Onboarding>();
    const SEARCH_TIMEOUT = 1000;
    // состояние для нового количества шагов
    const [newStepsCount, setNewStepsCount] = useState(0);
    const [hasViewedSteps, setHasViewedSteps] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [activeStep, setActiveStep] = useState(undefined);

    // const delay = useMemo(() => getOnboardingDelay(history.location.pathname), [history]);
    // автоматический онбординг
    const startAutoTour = useCallback(
        (key: string, isMobile: boolean) => {
            if (EXOnboarding.current) {
                EXOnboarding.current?.startAutoTour({ page: key, isMobile, searchTimeout: SEARCH_TIMEOUT });
            }
        },
        [EXOnboarding.current],
    );
    // конфигурация шагов для онбординга страницы
    const getPageSteps = (isMobile: boolean) => {
        const pageKey = getOnboardingKey(history?.location?.pathname);
        const data = { page: pageKey, isMobile, searchTimeout: SEARCH_TIMEOUT };
        if (pageKey) {
            void EXOnboarding.current?.getStepsOnPage(data);
        } else {
            setNewStepsCount(0);
            setHasViewedSteps(false);
        }
    };

    // старт онбординга страницы
    const startPageTour = (type?: TTourType) => {
        const pageKey = getOnboardingKey(history?.location?.pathname);

        if (pageKey) {
            void EXOnboarding.current?.startTour(pageKey, type);
        }
    };
    // остановка текущего онбординга
    const stopActiveTour = () => {
        setTimeout(() => {
            void EXOnboarding.current?.stopActiveTour();
        }, 500);
    };

    //создаем экземпляры классов
    useEffect(() => {
        EXOnboarding.current = new $Onboarding({
            initialStepsList: ONBOARDING_STEPS,
            project: 'lms',
        });

        return () => {
            EXOnboarding.current?.removeListeners();
        };
    }, []);

    // Подписываемся на изменения кол-ва непросмотренных шагов
    useEffect(() => {
        if (EXOnboarding.current) {
            //подписываемся на обновления уведомлений
            EXOnboarding.current.eventEmitter.on('stepsCountUpdated', setNewStepsCount);
            //подписываемся на обновления активного шага
            EXOnboarding.current.eventEmitter.on('activeStepUpdated', setActiveStep);
            //обновления состояния фетча (пока грузится апи)
            EXOnboarding.current.eventEmitter.on('isOnboardingFetching', setIsFetching);
            //подписываемся на обновления статуса просмотренности тура
            EXOnboarding.current.eventEmitter.on('tourViewedStatusUpdated', setHasViewedSteps);

            // Очистка подписки
            return () => {
                EXOnboarding.current?.eventEmitter.off('stepsCountUpdated', setNewStepsCount);
            };
        }
    }, [EXOnboarding.current]);

    return {
        hasViewedSteps,
        newStepsCount,
        startAutoTour,
        isFetching,
        stopActiveTour,
        getPageSteps,
        startPageTour,
        hasActiveStep: !!activeStep,
    };
};
