import { APP_ON_BOARDING } from './app';
import { HOME_ON_BOARDING } from './home';
import { HOMEWORKS_ON_BOARDING } from './homeworks';
import { LIBRARY_ON_BOARDING } from './library';
import { NEWS_ON_BOARDING } from './news';
import { PERFORMANCE_ON_BOARDING } from './performance';
import { SCHEDULE_ON_BOARDING } from './schedule';
import { SUBJECTS_ON_BOARDING } from './subjects';

export const ONBOARDING_STEPS = [
    ...APP_ON_BOARDING,
    ...HOME_ON_BOARDING,
    ...NEWS_ON_BOARDING,
    ...LIBRARY_ON_BOARDING,
    ...PERFORMANCE_ON_BOARDING,
    ...SUBJECTS_ON_BOARDING,
    ...SCHEDULE_ON_BOARDING,
    ...HOMEWORKS_ON_BOARDING,
];
