import React, { useCallback, useEffect, useState } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { PlusCircle, Trash } from '@lms-elements/icons';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { useRole } from 'hooks';
import { Mark } from 'src-new/components/lms-elements/Mark';
import { MarkScore } from 'types/mark';

import { Input } from 'components/Input';

import { IAttendanceMarkFormProps, MarkField } from './AttendanceMarkForm.types';
import { getIsValidCommentSize, isValidComments } from './AttendanceMarkForm.utils';

import './AttendanceMarkForm.scss';

const CnAttendanceMarkForm = cn('attendanceMarkAdd');

export const AttendanceMarkForm: React.FC<IAttendanceMarkFormProps> = ({
    isChangeForm,
    initialValue,
    closeModal,
    withoutSubmitType,
    onSubmit,
    canCreateCommentOnly,
    onceMark,
    withoutComment,
}) => {
    const [newMarks, setNewMarks] = useState<MarkField[]>(initialValue);
    const [canSubmit, setCanSubmit] = useState(false);
    const { isPrincipal: isPrincipalRole, isSupport } = useRole();
    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что завуч
    const isPrincipal = isPrincipalRole || isSupport;

    const validateCanSubmitIsChange = () => {
        const changedMarks = newMarks.filter((mark) => {
            const oldMark = initialValue.find((oldMark) => oldMark.markId === mark.markId);
            if (oldMark) {
                return oldMark.selectedMark !== mark.selectedMark;
            } else {
                return true;
            }
        });

        return isValidComments(changedMarks);
    };

    useEffect(() => {
        if (newMarks.length) {
            let isCanSubmit = true;

            if (withoutComment) {
                return setCanSubmit(true);
            } else if (isChangeForm) {
                isCanSubmit = validateCanSubmitIsChange();
            } else {
                isCanSubmit = isValidComments(newMarks);
            }

            setCanSubmit(
                isCanSubmit &&
                    newMarks.every(
                        (mark) => Boolean(mark.selectedMark) || (canCreateCommentOnly && Boolean(mark.comment)),
                    ),
            );
        } else {
            setCanSubmit(false);
        }
    }, [canCreateCommentOnly, newMarks, withoutComment]);

    const makeHandlerMarkDelete = useCallback(() => {
        setCanSubmit(true);
    }, []);

    const makeHandlerMarkClick = useCallback(
        (
            eventHandler: (event: Record<string, Record<string, number | undefined>>) => void,
            mark: MarkScore,
            id: number,
        ) => {
            return () => {
                setNewMarks((prevState) => {
                    eventHandler({ target: { value: mark === prevState[id]?.selectedMark ? undefined : mark } });

                    return prevState.map((markField, idx) => {
                        if (idx === id) {
                            return markField.selectedMark === mark
                                ? { ...markField, selectedMark: undefined }
                                : { ...markField, selectedMark: mark };
                        } else {
                            return markField;
                        }
                    });
                });
            };
        },
        [],
    );

    const makeHandlerMarkChange = (
        eventHandler: React.ChangeEventHandler<HTMLInputElement>,
        id: number,
    ): React.ChangeEventHandler<HTMLInputElement> => {
        return (event) => {
            setNewMarks(
                newMarks.map((mark, idx) =>
                    idx === id ? { ...mark, comment: event?.currentTarget?.value ?? '' } : mark,
                ),
            );
            eventHandler(event);
        };
    };

    const handleCancelButtonClick = useCallback(() => {
        setNewMarks(initialValue);
        closeModal();
    }, [closeModal, initialValue]);

    const makeHandlerFormSubmit = useCallback(
        (submitHandler: (values: { mark: MarkField[] }) => void) => {
            return (
                values: { mark: MarkField[] },
                formApi: FormApi<{ mark: MarkField[] }, Partial<{ mark: MarkField[] }>>,
            ) => {
                const { modified } = formApi.getState();

                setNewMarks([]);
                submitHandler({
                    ...values,
                    mark: values.mark.filter(
                        (mark, index) =>
                            mark.isDeleted ||
                            ((canCreateCommentOnly || mark.selectedMark !== undefined) &&
                                (modified?.[`mark[${index}].comment`] ||
                                    modified?.[`mark[${index}].selectedMark`] ||
                                    modified?.['mark'] ||
                                    !isChangeForm)),
                    ),
                });
            };
        },
        [canCreateCommentOnly, isChangeForm],
    );

    const getCommentField = (
        name: string,
        index: number,
        fields: FieldArrayRenderProps<MarkField, HTMLElement>['fields'],
        isNewMark?: boolean,
    ) => {
        const errorText = 'Комментарий должен быть больше 30 символов';
        const disabled = isNewMark ? !fields.value[index].valueCanChange : false;
        const isChangedMark = isChangeForm ? newMarks[index]?.selectedMark !== initialValue[index]?.selectedMark : true;
        const isInvalidCommentSize = isChangedMark && !getIsValidCommentSize(newMarks[index]);
        const isNeedComment =
            isInvalidCommentSize &&
            (isNewMark ? fields.value[index].valueCanChange && fields.value[index].teacherMark : true);

        return (
            <div className={CnAttendanceMarkForm('commentInputContainer')}>
                <Field name={`${name}.comment`}>
                    {({ input: { onChange, value } }: FieldRenderProps<string, HTMLInputElement>) => (
                        <Input
                            className={CnAttendanceMarkForm('textArea', {
                                needComment: isNeedComment,
                            })}
                            value={value}
                            onChange={makeHandlerMarkChange(onChange, index)}
                            placeholder="Оставьте комментарий (теперь можно вводить LaTeX-формулы)"
                            as={'textarea'}
                            disabled={disabled}
                        />
                    )}
                </Field>
                {isInvalidCommentSize ? <span>{errorText}</span> : null}
            </div>
        );
    };

    const onCreateMarkPress = (id: number) => {
        const newMark = {
            comment: '',
            selectedMark: undefined,
            markId: id,
            valueCanChange: true,
        };
        setNewMarks((state) => {
            const newState = [...state];
            newState[id] = newMark;
            return newState;
        });
    };

    return (
        <div className={CnAttendanceMarkForm()}>
            <Form
                onSubmit={makeHandlerFormSubmit(onSubmit)}
                initialValues={{ mark: initialValue }}
                mutators={{ ...arrayMutators }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <FieldArray<MarkField> name={'mark'}>
                            {({ fields }) => {
                                return fields.map((name, index) => {
                                    if (fields.value[index].isDeleted) {
                                        return null;
                                    }
                                    if (!isChangeForm && index !== Number(fields.length) - 1) {
                                        return (
                                            <div key={index}>
                                                <div className={CnAttendanceMarkForm('header', { added: true })}>
                                                    <div className={CnAttendanceMarkForm('addedMark')}>
                                                        <Field name={`${name}.selectedMark`}>
                                                            {({
                                                                input: { value },
                                                            }: FieldRenderProps<number | null, HTMLInputElement>) => (
                                                                <Mark
                                                                    markId={''}
                                                                    value={value ? value : 0}
                                                                    needHideWeight
                                                                    needTrimComment={false}
                                                                />
                                                            )}
                                                        </Field>
                                                        {(isPrincipal || fields.value[index].teacherMark) && (
                                                            <div
                                                                className={CnAttendanceMarkForm('trash')}
                                                                onClick={() => {
                                                                    fields.remove(index);
                                                                }}
                                                            >
                                                                <Trash />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {!withoutComment && getCommentField(name, index, fields)}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={index}>
                                                <div className={CnAttendanceMarkForm('header')}>
                                                    <Field name={`${name}.selectedMark`}>
                                                        {({
                                                            input: { onChange, value },
                                                        }: FieldRenderProps<MarkScore | null, HTMLInputElement>) => (
                                                            <div className={CnAttendanceMarkForm('mark')}>
                                                                {isChangeForm ? (
                                                                    fields.value[index].teacherMark ? (
                                                                        !fields.value[index].valueCanChange ? (
                                                                            <span
                                                                                className={CnAttendanceMarkForm(
                                                                                    'title',
                                                                                )}
                                                                            >
                                                                                Оценка
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className={CnAttendanceMarkForm(
                                                                                    'title',
                                                                                )}
                                                                            >
                                                                                Редактировать оценку
                                                                            </span>
                                                                        )
                                                                    ) : (
                                                                        <span className={CnAttendanceMarkForm('title')}>
                                                                            {`Попытка №${
                                                                                fields.value[index].attemptMarkNumber ??
                                                                                ''
                                                                            }`}
                                                                        </span>
                                                                    )
                                                                ) : (
                                                                    <span className={CnAttendanceMarkForm('title')}>
                                                                        {index >= 1
                                                                            ? `Выберите ${index + 1}-ю оценку`
                                                                            : 'Добавить оценку'}
                                                                    </span>
                                                                )}
                                                                {(fields.value[index].valueCanChange || !isChangeForm
                                                                    ? ([5, 4, 3, 2] as MarkScore[])
                                                                    : value
                                                                    ? [value]
                                                                    : []
                                                                ).map((renderMark) => (
                                                                    <div
                                                                        className={CnAttendanceMarkForm('currMark', {
                                                                            selected: value === renderMark,
                                                                            withoutComment,
                                                                        })}
                                                                        onClick={
                                                                            fields.value[index].valueCanChange ||
                                                                            !isChangeForm
                                                                                ? makeHandlerMarkClick(
                                                                                      onChange,
                                                                                      renderMark,
                                                                                      index,
                                                                                  )
                                                                                : undefined
                                                                        }
                                                                        key={renderMark}
                                                                    >
                                                                        <Mark
                                                                            value={renderMark}
                                                                            markId={''}
                                                                            needHideWeight
                                                                            needTrimComment={false}
                                                                        />
                                                                    </div>
                                                                ))}
                                                                {canSubmit && !isChangeForm && !onceMark && (
                                                                    <div
                                                                        onClick={() => {
                                                                            fields.push({
                                                                                comment: '',
                                                                                selectedMark: undefined,
                                                                                markId: 0,
                                                                                valueCanChange: true,
                                                                            });
                                                                            onCreateMarkPress(fields?.length ?? 0);
                                                                        }}
                                                                        className={CnAttendanceMarkForm('headerAction')}
                                                                    >
                                                                        <PlusCircle />
                                                                    </div>
                                                                )}
                                                                {isChangeForm && fields.value[index].valueCanChange && (
                                                                    <div
                                                                        onClick={() => {
                                                                            fields.update(index, {
                                                                                ...fields.value[index],
                                                                                isDeleted: true,
                                                                            });
                                                                            makeHandlerMarkDelete();
                                                                        }}
                                                                        className={CnAttendanceMarkForm(
                                                                            'headerAction',
                                                                            {
                                                                                isChangeForm,
                                                                            },
                                                                        )}
                                                                    >
                                                                        <Trash />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                                {!withoutComment && getCommentField(name, index, fields, true)}
                                            </div>
                                        );
                                    }
                                });
                            }}
                        </FieldArray>
                        <div className={CnAttendanceMarkForm('footer')}>
                            <div className={CnAttendanceMarkForm('button')}>
                                <Button
                                    type={withoutSubmitType ? 'button' : 'submit'}
                                    view={ButtonViewEnum.action}
                                    onClick={withoutSubmitType ? handleSubmit : undefined}
                                    size={'l'}
                                    disabled={!canSubmit}
                                >
                                    <span className={CnAttendanceMarkForm('button-text')}>Сохранить</span>
                                </Button>
                            </div>
                            <div className={CnAttendanceMarkForm('button', { isCancel: true })}>
                                <Button onClick={handleCancelButtonClick} view={ButtonViewEnum.bordered} size={'l'}>
                                    <span className={CnAttendanceMarkForm('button-text')}>Отмена</span>
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </Form>
        </div>
    );
};
