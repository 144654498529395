import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Dots, Pen, PlusCircle } from '@lms-elements/icons';
import { useRole } from 'hooks';
import { Mark } from 'src-new/components/lms-elements/Mark';

import { IAttendanceMarkProps } from './AttendanceMark.types';
import { AttendanceMarkForm, MarkField } from './AttendanceMarkForm';

import './AttendanceMark.scss';

const CnAttendanceMark = cn('attendanceMark');

export const AttendanceMark: React.FC<IAttendanceMarkProps> = ({
    marks,
    teacherId,
    submitCreateMark,
    submitChangeMark,
    onDeleteMark,
    isNeedAddMark = true,
    isNeedChangeMark = isNeedAddMark,
    isStudent,
    canCreateCommentOnly,
    onceMark,
    withoutComment,
    onCancel,
    needHideWeight,
    isAbsolutionPosition,
    onAcademicPerfomance,
    statusCard = false,
}) => {
    const [isAddMarksFormShow, setIsAddMarksFormShow] = useState(false);
    const [isChangeMarksFormShow, setIsChangeMarksFormShow] = useState(false);
    const [formPosition, setformPosition] = useState<CSSProperties>({ top: -9999, left: -9999 });

    const { isPrincipal: isPrincipalRole, isSupport } = useRole();
    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что завуч
    const isPrincipal = isPrincipalRole || isSupport;
    const changeIsAddMarksFormShowCallback = useCallback(
        (e?: React.MouseEvent<HTMLDivElement>) => {
            if (e) {
                const plusIcon = e.currentTarget as HTMLDivElement;
                const { x, y } = plusIcon.getBoundingClientRect();

                setformPosition({ top: y + 40, left: x - 200, maxHeight: `calc(100vh - ${y + 40}px - 50px)` });
            }

            setIsAddMarksFormShow(!isAddMarksFormShow);
        },
        [isAddMarksFormShow],
    );

    const changeformRef = useRef<HTMLDivElement>(null);
    const createformRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (changeformRef.current || createformRef.current) {
            const width = 440;
            setformPosition((prev) => {
                if (Number(prev.left) + width + 30 > window.innerWidth) {
                    return {
                        ...prev,
                        left: window.innerWidth - width - 30,
                    };
                }

                if (Number(prev.left) < 30) {
                    return {
                        ...prev,
                        left: 30,
                    };
                }

                return prev;
            });
        }
    }, [formPosition.left]);

    const changeIsChangeMarksFormShowCallback = useCallback(
        (e?: React.MouseEvent<HTMLDivElement>) => {
            if (e) {
                const penIcon = e?.currentTarget as HTMLDivElement;
                const { x, y } = penIcon.getBoundingClientRect();

                setformPosition({ top: y + 40, left: x - 200, maxHeight: `calc(100vh - ${y + 40}px - 50px)` });
            }

            setIsChangeMarksFormShow(!isChangeMarksFormShow);
        },
        [isChangeMarksFormShow],
    );

    const handleCancelClick = useCallback(() => {
        onCancel?.();
        setIsChangeMarksFormShow(false);
    }, [onCancel]);

    const handleSubmitIsAddMarksForm = useCallback(
        (values: { mark: MarkField[] }) => {
            if (submitCreateMark) {
                submitCreateMark(values.mark);
            }
            setIsAddMarksFormShow(false);
        },
        [submitCreateMark],
    );

    const handleSubmitIsChangeMarksForm = useCallback(
        (values: { mark: MarkField[] }) => {
            const changedMarks: MarkField[] = [];

            values.mark.forEach((mark) => {
                if (mark.isDeleted) {
                    onDeleteMark?.(mark.markId, mark.attemptMarkNumber, mark.teacherMark);
                } else {
                    changedMarks.push(mark);
                }
            });

            submitChangeMark?.(changedMarks);

            setIsChangeMarksFormShow(false);
        },
        [onDeleteMark, submitChangeMark],
    );

    const filteredMarks = useMemo(
        () =>
            marks
                .filter((mark) => {
                    if (isPrincipal) {
                        return true;
                    }
                    if (teacherId) {
                        return mark.author === teacherId;
                    }
                    return true;
                })
                .map((mark) => {
                    return {
                        ...mark,
                        valueCanChange: mark.valueCanChange || teacherId === mark.author || isPrincipal,
                        teacherMark: mark.teacherMark || teacherId === mark.author,
                        attemptMarkNumber: mark.attemptMarkNumber,
                    };
                }),
        [isPrincipal, marks, teacherId],
    );

    const noMarks = useMemo(() => marks.length === 0, [marks]);

    useEffect(() => {
        if (isChangeMarksFormShow) {
            changeformRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [isChangeMarksFormShow]);

    useEffect(() => {
        if (isAddMarksFormShow) {
            createformRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [isAddMarksFormShow]);

    const changeMarkInitList = useMemo(
        () =>
            filteredMarks.map((el) => ({
                comment: el.comment,
                selectedMark: el.score,
                markId: el.id,
                valueCanChange: el.valueCanChange,
                teacherMark: el.teacherMark,
                attemptMarkNumber: el.attemptMarkNumber,
            })),
        [filteredMarks],
    );

    const addMarkInitList = useMemo(() => [{ comment: '', markId: 0, valueCanChange: true }], []);

    return (
        <div className={CnAttendanceMark()}>
            <div className={CnAttendanceMark('marks', { statusCard })}>
                {marks.slice(0, statusCard ? 4 : marks.length).map(({ id, score, ...mark }, index, array) => {
                    return (
                        <div
                            className={CnAttendanceMark('mark', { statusCard })}
                            key={id}
                            onClick={statusCard ? onAcademicPerfomance : undefined}
                        >
                            <Mark
                                {...mark}
                                author={
                                    mark.authorLastName
                                        ? `${mark.authorLastName ?? ''} ${mark.authorFirstName ?? ''} ${
                                              mark.authorPatronymic ?? ''
                                          }`
                                        : undefined
                                }
                                value={Number(score) || 0}
                                markId={String(id)}
                                needHideWeight={needHideWeight}
                                needTrimComment={false}
                            />
                        </div>
                    );
                })}
                {statusCard && marks.length > 4 && (
                    <div className={CnAttendanceMark('dots')} onClick={statusCard ? onAcademicPerfomance : undefined}>
                        <Dots />
                    </div>
                )}
            </div>
            {isNeedChangeMark && filteredMarks.length !== 0 && !isStudent && (
                <div onClick={changeIsChangeMarksFormShowCallback} className={CnAttendanceMark('change')}>
                    <Pen />
                </div>
            )}
            {isNeedAddMark && !isStudent && (
                <div onClick={changeIsAddMarksFormShowCallback} className={CnAttendanceMark('add', { noMarks })}>
                    <PlusCircle />
                </div>
            )}

            {(isAddMarksFormShow || isChangeMarksFormShow) && <div className={CnAttendanceMark('modalBackground')} />}

            {isChangeMarksFormShow && (
                <div
                    ref={changeformRef}
                    className={`${CnAttendanceMark('modal', {
                        show: isChangeMarksFormShow,
                        absolution: isAbsolutionPosition,
                    })} attendanceMark-form`}
                    style={isAbsolutionPosition ? {} : formPosition}
                >
                    <AttendanceMarkForm
                        isChangeForm={true}
                        onSubmit={handleSubmitIsChangeMarksForm}
                        initialValue={changeMarkInitList}
                        closeModal={handleCancelClick}
                        canCreateCommentOnly={canCreateCommentOnly}
                    />
                </div>
            )}
            {isAddMarksFormShow && (
                <div
                    ref={createformRef}
                    className={`${CnAttendanceMark('modal', {
                        show: isAddMarksFormShow,
                        absolution: isAbsolutionPosition,
                    })} attendanceMark-form`}
                    style={isAbsolutionPosition ? {} : formPosition}
                >
                    <AttendanceMarkForm
                        onSubmit={handleSubmitIsAddMarksForm}
                        initialValue={addMarkInitList}
                        closeModal={changeIsAddMarksFormShowCallback}
                        canCreateCommentOnly={canCreateCommentOnly}
                        onceMark={onceMark}
                        withoutComment={withoutComment}
                    />
                </div>
            )}
        </div>
    );
};
