/**
 *
 * @param ctx
 */
export const PERFORMANCE_ON_BOARDING = [
    {
        target: '[data-onboarding="performanceContainer"]', //вся таблица
        key: 'performance_1',
    },
    {
        target: '[data-onboarding="performanceTable"] .tabs-header', // табы с учебными периодами
        key: 'performance_2',
    },
    {
        target: '[data-onboarding="performanceDatePicker"]', // выбор дат
        key: 'performance_4',
    },
    {
        target: '.performance-table-container .mark_hoverable', // кружок с оценкой
        key: 'performance_5',
    },
    {
        target: '[data-onboarding="markComment"]', // комментарий/оценка с комментом
        key: 'performance_6',
    },
    {
        target: '[data-onboarding="marksOverflowContainer"]', // более трех оценок за урок (со стрелочкой для разворота)
        key: 'performance_7',
    },
    {
        target: '.pt-table-quarter-marks .mark_notApproved', // предварительная оценка за период (в пунктире)
        key: 'performance_8',
    },
    {
        target: '.pt-table-quarter-marks .mark_accept', // итоговая оценка за период (в пунктире)
        key: 'performance_9',
    },
    {
        target: '[data-onboarding="weightedPeriodMark"]', // средневзвешенная оценка за период (в пунктире)
        key: 'performance_10',
    },
    {
        target: '[data-onboarding="absentLabel"]', // н-ка
        key: 'performance_11',
    },
];
