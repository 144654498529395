/**
 *
 * @param ctx
 */
export const LIBRARY_ON_BOARDING = [
    {
        target: '[data-onboarding="libraryContainer"]',
        key: 'library_1',
    },
    {
        target: '[data-onboarding="subjectSelector"]', // выбор предмета
        key: 'library_2',
    },
    {
        target: '.app-collapse-item-active [data-onboarding="booksCategoriesFilter"]', // фильтр по категориям
        key: 'library_3',
    },
    {
        target: '[data-onboarding="booksSearchingInput"]', // поиск
        key: 'library_4',
    },
];
