/**
 *
 * @param ctx
 */
export const SUBJECTS_ON_BOARDING = [
    {
        target: '[data-onboarding="subjectsContainer"]',
        key: 'subjects_1',
    },
    {
        target: '[data-onboarding="teacherButton"]', //кнопка учитель
        key: 'subjects_2',
    },
    {
        target:
            '[data-onboarding="NewSubjectsCard"]:first-child .subject-course-card [data-onboarding="progressesBars"]', //прогрессы у предмета/темы/урока
        key: 'subjects_3',
        multiple: true,
        placement: 'bottom',
    },
    {
        target: '[data-onboarding="lessonTypeLabel"]', // тэг типа урока
        key: 'subjects_4',
    },
    {
        target: '[data-onboarding="lessonsColFilters"]', //фильтры уроков (Контрольная, Онлайн, Самоподготовка)
        key: 'subjects_5',
        multiple: true,
        placement: 'bottom',
    },
    {
        target: '[data-onboarding="needAttentionFilter"]', // фильтр "Требующие внимания"
        key: 'subjects_6',
    },
    {
        target: '[data-onboarding="pastLessonToggle"]', //свитчер "прошедшие уроки"
        key: 'subjects_7',
    },
];
