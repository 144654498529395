/**
 *
 * @param ctx
 */
export const HOMEWORKS_ON_BOARDING = [
    {
        target: '[data-onboarding="privateLayout"]',
        placement: 'center',
        key: 'homework_1',
    },
    {
        target: '[data-onboarding="homeworkTabs"] .tabs-header', // табы
        key: 'homework_2',
    },
    {
        target: '[data-onboarding="courseSelector"]', //Выбор курса
        key: 'homework_3',
    },
    {
        target: '[data-onboarding="timeToPassTask"]', // Время до сдачи
        key: 'homework_4',
    },
    {
        target: '[data-onboarding="overdueTask"]', // просроченное задание
        key: 'homework_5',
    },
    {
        target: '[data-onboarding="overdueLessonWithTask"]', // просроченный урок, на котором выдали задание
        key: 'homework_6',
    },
    {
        target: '[data-onboarding="hiddenOverdueTasksOption"]', // чекбокс скрытия рекомендованных заданий
        key: 'homework_7',
    },
];
