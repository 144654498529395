/**
 * @description - получаем ключ онбординга в зависимости от страницы
 * @param router
 */
export const getOnboardingKey = (router: string): string => {
    switch (router) {
        case '/': {
            return 'home';
        }
        case '/news':
        case '/news/': {
            return 'news';
        }
        case '/new-subjects':
        case '/new-subjects/': {
            return 'subjects';
        }
        case '/schedule':
        case '/schedule/': {
            return 'schedule';
        }
        case '/performance':
        case '/performance/': {
            return 'performance';
        }
        case '/library':
        case '/library/': {
            return 'library';
        }
        case '/homework':
        case '/homework/': {
            return 'homework';
        }
        default:
            return '';
    }
};
/**
 * @description - получаем задержку на запрос шагов в зависимости от страницы
 * (нужно для медленных страниц)
 * @param router
 */
export const getOnboardingDelay = (router: string): number => {
    switch (router) {
        case '/performance': {
            return 1000;
        }
        default:
            return 0;
    }
};
